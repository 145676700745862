body, input, select, textarea {
  font-family: $font-ja;
  font-size: 16px;
  color: $color_black;
  &.ja, .ja {
    font-family: $font-ja;
  }
  @include breakpoint('<=medium') {
    font-size: 14px;
  }
}
a {
  color: $color_black;
  text-decoration: none;
  outline: none;
  &:hover {
    color: $color_black;
    text-decoration: none;
    outline: none;
  }
}
.font-heading {
  font-weight: 800;
  &.is-1 {
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: 5px;
  }
  &.is-2 {
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 1px;
  }
  &.is-3 {
    font-size: 20px;
    line-height: 1.8;
    letter-spacing: 1px;
  }
  @include breakpoint('<=medium') {
    &.is-1 {
      font-size: 24px;
    }
    &.is-2 {
      font-size: 28px;
    }
    &.is-3 {
      font-size: 18px;
    }
  }
  @include breakpoint('<=small') {
    &.is-1 {
      font-size: 22px;
    }
    &.is-2 {
      font-size: 24px;
    }
    &.is-3 {
      font-size: 18px;
    }
  }
}
.font-text {
  &.is-1 {
    font-size: 16px;
    line-height: 1.5;
  }
  &.is-2 {
    font-size: 14px;
    line-height: 1.5;
  }
  &.is-3 {
    font-size: 12px;
    line-height: 1.5;
  }
  @include breakpoint('<=small') {
    &.is-1 {
      font-size: 14px;
      line-height: 1.5;
    }
    &.is-2 {
      font-size: 12px;
      line-height: 1.5;
    }
    &.is-3 {
      font-size: 10px;
      line-height: 1.5;
    }
  }
}