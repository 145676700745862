.nav{
  &.active {
    .nav-outer {
      left: -100vw;
      right: 0;
      width: 200vw;
    }
    .nav-inner {
      right: 0;
    }
  }
  .nav-outer {
    @include vendor('transition', 'all .3s');
    width: 0;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 100%;
    right: -100%;
    bottom: 0;
    background: rgba(29,150,189,.8);
    z-index: 2;
  }
  .nav-inner {
    @include vendor('transition', 'all .3s');
    width: 340px;
    position: fixed;
    top: 0;
    right: -340px;
    bottom: 0;
    z-index: 3;
    ul {
      position: relative;
      margin: 7rem auto 0;
      max-width: 360px;
      li {
        display: block;
        margin: .5rem 4rem 2rem;
        padding-bottom: .5rem;
        overflow: hidden;
        &.border-bottom-none {
          border-bottom: 0;
          margin-bottom: 0;
        }
        > ul {
          margin-top: 1rem;
          li {
            margin-right: 0;
            margin-bottom: 1rem;
            img {
              height: 20px;
              padding-right: 10px;
              position: relative;
              top: 2px;
            }
            .map-btn {
              padding: 5px 10px;
              color: $color_white;
              float: right;
            }
          }
        }
        a {
          color: $color_white;
          display: inline-block;
          position: relative;
          @include vendor('transition-duration', '.3s');
          &::before {
            content: " ";
            background-image: url(../../images/arrow_right_white.svg);
            background-repeat: no-repeat;
            background-position: center left;
            width: 1.25rem;
            height: .8rem;
            display: inline-block;
          }
          &::after {
            position: absolute;
            bottom: -.5rem;
            left: 0;
            content: '';
            width: 0;
            height: 1px;
            background-color: $color_white;
            @include vendor('transition-duration', '.3s');
          }
          &:hover {
            color: $color_white;
          }
          &:hover::after {
            width: 100%;
          }
        }
      }
    }
    .nav-logo {
      margin: 2rem;
      text-align: center;
      img {
        width: 160px;
      }
    }
    .nav-contact {
      margin: 2rem 4rem;
      @include vendor('display', 'flex');
      @include vendor('flex-wrap', 'wrap');
      @include vendor('justify-content', 'space-between');
    }
  }
  @include breakpoint('<=small') {
    &.active {
      right: 0;
      left: 0;
      width: 100%;
    }
    .nav-inner {
      width: 100%;
      right: -100%;
      ul {
        max-width: 260px;
        li {
          margin: .5rem 1rem 1rem;
          > ul {
            margin-top: .5rem;
            li {
              margin-bottom: .5rem;
            }
          }
        }
      }
      .nav-logo {
        margin: 2rem 2rem 1rem;
        img {
          width: 100px;
        }
      }
      .nav-contact {
        margin: 1rem;
      }
    }
  }
}