section {
  margin: 0 auto;
  padding: 2rem;
  max-width: $max-width;
  position: relative;
  min-height: calc(100vh - 450px);

  @include breakpoint('<=header') {
  }
  @include breakpoint('<=medium') {
  }
  @include breakpoint('<=small') {

  }
}