.list {
  li {
    &:before {
      content: "*";
      padding-right: 5px;
      font-weight: 900;
    }
    > .list {
      margin-left: 2rem;
    }
  }
  @include breakpoint('<=small') {
    li {
      a {
        padding: .3rem 0 0;
      }
    }
  }
}
.list-dot,
.list-caution {
  position: relative;
  li {
    padding-left: 1.25rem;
    &:before {
      content: "・";
      position: absolute;
      left: 0;
    }
  }
}
.list-caution {
  li {
    &:before {
      content: "※";
    }
  }
}