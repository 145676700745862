header {
  max-width: $max-width-header;
  padding: 2rem;
  position: relative;
  margin: auto;

  h1 {
    font-size: 0;
  }
  img {
    max-width: 200px;
  }


  .header-inner {
    .menu.pc {
      display: block;
      position: absolute;
      padding: 2rem;
      top: 1.4rem;
      right: 2rem;
      z-index: 10;
      @include vendor('align-items', 'center');
      @include vendor('transition', 'opacity .3s');
      nav ul {
        @include vendor('display', 'flex');
        @include vendor('align-items', 'center');
        @include vendor('transition', 'all .3s');
        @include vendor('gap', '1rem');
        li {
          margin-left: 1rem;
          a {
            color: $color_white;
            display: inline-block;
            position: relative;
            text-wrap: nowrap;
            white-space: nowrap;
            @include vendor('transition-duration', '.3s');
            &::before {
              content: " ";
              background-image: url(../../images/arrow_right_white.svg);
              background-repeat: no-repeat;
              background-position: center left;
              width: 1.25rem;
              height: .8rem;
              display: inline-block;
            }
            &::after {
              position: absolute;
              bottom: -.5rem;
              left: 0;
              content: '';
              width: 0;
              height: 1px;
              background-color: $color_white;
              @include vendor('transition-duration', '.3s');
            }
            &:hover {
              color: $color_white;
            }
            &:hover::after {
              width: 100%;
            }
          }
        }
      
      }
    }
    .menu.link {
      @include vendor('display', 'flex');
      display: none;
      position: absolute;
      right: 2rem;
      top: 3.7rem;
      z-index: 10;
      @include vendor('align-items', 'center');
      @include vendor('transition', 'opacity .3s');
      .menu-status {
        &:after {
          content: "MENU";
          font-family: $font-ja;
          font-size: 14px;
          line-height: 1;
          color: $color_white;
          @include vendor('transition', 'all .3s');
        }
      }
      .menu-toggle {
        margin-left: 5px;
        span {
          display: block;
          width: 15px;
          height: 2px;
          background: $color_white;
          @include vendor('transition', 'all .3s');
          &:before,
          &:after {
            content: "";
            width: 15px;
            height: 2px;
            background: $color_white;
            @include vendor('transition', 'all .3s');
            position: absolute;
          }
          &:before {
            @include vendor('transform', 'translateY(-5px)');
          }
          &:after {
            @include vendor('transform', 'translateY(5px)');
          }
        }
      }
      &.active {
        .menu-status {
          &:after {
            content: "CLOSE";
            color: $color_white;
          }
        }
        .menu-toggle {
          span {
            background: none;
            &:before{
              background: $color_white;
              @include vendor('transform', 'translateY(0px) rotate(45deg)');
            }
            &:after{
              background: $color_white;
              @include vendor('transform', 'translateY(0px) rotate(-45deg)');
            }
          }
        }
      }
    }
  }

  @include breakpoint('<=header') {
    padding: 2rem;
    .header-inner {
      .menu.pc {
        display: block;
      }
      .menu.link {
        display: none;
      }
    }
  }
  @include breakpoint('<=medium') {
    padding: 2rem 1rem;

    .header-inner {
      .menu.pc {
        display: none;
      }
      .menu.link {
        display: flex;
      }
      .menu.link {
        &:hover {
          .menu-toggle {
            pointer-events: none;
          }
        }
      }
    }
  }
}