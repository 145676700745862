$color_white : rgb(255, 255, 255);
$color_black : rgb(33, 33, 33);
$color_gray  : rgb(123, 123, 123);
$color_blue : rgba(29,150,189,1);
$color_blue_dark : #21627a;
$footer_color : #21627a;//rgba(29,150,189,1);

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
$font-ja : "Noto Sans JP", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Meiryo", "MS Pゴシック", "osaka", sans-serif;

$max-width: 800px;
$max-width-header: 1480px;

$max-height-header : 192px;
$max-height-footer : 225px;

html {
  scroll-behavior: smooth;
}
body {
  position: relative;
  background: url(../../images/sky.svg) repeat-x;
  background-size: auto calc(100% - 250px);
  min-height: 100vh;
  touch-action: manipulation;
  &.noScroll {
    overflow: hidden;
  }
}



