#main {
  opacity: 0;
  position: relative;
  top: 30px;
  @include vendor('transition', 'all 3s');
  &.loaded {
    opacity: 1;
    top: 0;
  }
}
#header-back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 500px;
  z-index: -1;
  background: linear-gradient(355deg, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 88%, rgba(29,150,189,1) 111%);
}
.content-wrap {
  padding: 100px 0 80px;
  .message {
    text-align: center;
    line-height: 2.5rem;
    max-width: 485px;
    margin: 0 auto 1rem;
    font-weight: 700;
    letter-spacing: 1px;
  }
  @include breakpoint('<=medium') {
    padding: 80px 0 80px;
    .message {
      text-align: left;
      line-height: 2rem;
    }
  }
  @include breakpoint('<=small') {
    padding: 20px 0 80px;
  }
}
.contents {
  position: relative;
  margin: 2rem auto 3rem;
  p,h1,h2,h3,h4,h5,h6 {
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  &.has-text-centered {
    text-align: center;
    .login-box {
      margin: 2rem auto 6rem;
    }
  }
  .has-text-centered {
    text-align: center;
  }
  .has-text-right {
    text-align: right;
  }
  h1 {
    color: $color_blue_dark;
  }
  .list-about {
    margin: 0 0 4rem 2rem;
    li {
      margin: .5rem 0;
    }
  }
  dl {
    dt {
      margin: 1rem 0;
      border-bottom: solid 3px $color_blue_dark;
      color: $color_blue_dark;
    }
    dd {
      margin: 0 0 2rem 2rem;
      @include breakpoint('<=medium') {
        margin-left: 0;
      }
      p {
        margin: .5rem 0;
      }
      ul {
        li {

        }
      }
    }
  }
  dl.dl-about {
    dt {
      display: inline-block;
      width: 100px;
      line-height: 2;
      border-bottom: none;
      font-weight: bold;
      color: $color_black;
      @include breakpoint('<=small') {
        width: 60px;
      }
    }
    dd {
      display: inline-block;
      width: calc(100% - 100px - 2.5rem);
      line-height: 2;
      vertical-align: top;
      margin-bottom: 0;
      margin-top: 1rem;
      white-space: nowrap;
      @include breakpoint('<=small') {
        width: calc(100% - 60px - 2.5rem);
      }
    }
  }
}

br.pc {
  display: none;
}
@include breakpoint('>=medium') {
  br.pc {
    display: block;
  }
}