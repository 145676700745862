$propeller-width : 160px;
footer {
  .footer-wrapper {
    max-width: $max-width-header;
    margin: 0 auto;
    position: relative;
    padding-bottom: 2rem;
    &:before {
      content: "";
      width: 100%;
      position: absolute;
      top: -2rem;
      left: 0;
      border-top: solid 1px $footer_color;
    }
  }
  background-color: $color_white;
  padding: 2rem 0;
  text-align: center;
  color: $color_black;

  .copy {
    margin: 2rem auto 0;
  }

  nav {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        a {
          color: $footer_color;
          display: inline-block;
          position: relative;
          text-wrap: nowrap;
          white-space: nowrap;
          @include vendor('transition-duration', '.3s');
          &::before {
            content: " ";
            background-image: url(../../images/arrow_right_blue.svg);
            background-repeat: no-repeat;
            background-position: center left;
            width: 1.25rem;
            height: .8rem;
            display: inline-block;
          }
          &::after {
            position: absolute;
            bottom: -.5rem;
            left: 0;
            content: '';
            width: 0;
            height: 1px;
            background-color: $footer_color;
            @include vendor('transition-duration', '.3s');
          }
          &:hover {
            color: $footer_color;
          }
          &:hover::after {
            width: 100%;
          }
        }
        display: block;
        padding: 0 1rem;
        margin: 1rem auto;
      }
    }
  }

  @include breakpoint('>=medium') {
    padding: 0 1rem;
  }
  @include breakpoint('<=medium') {
    padding: 0 1rem;
    nav {
      ul {
        li {
          padding: 0 .5rem;
        }
      }
    }
  }
  @include breakpoint('<=small') {
    nav {
      ul {
        li {
          padding: 0;
          text-align: left;
        }
      }
    }
  }
}